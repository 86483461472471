import React, { useEffect } from "react"
import { Link } from "gatsby"

export default function Thankyou(props) {
  useEffect(() => {
    if (!props.location.state) {
      window.location = "/"
    }
  }, [])

  return (
    <>
      {props.location.state !== null && (
        <div
          className="d-flex"
          style={{
            backgroundColor: "#FDC836",
            height: "100vh",
            minHeight: "100%",
            alignItems: "center",
          }}
        >
          <div className="container text-center">
            <div
              className="jumbotron mx-auto"
              style={{
                backgroundColor: "#FDC836",
                color: "white",
                border: "2px solid white",
                borderRadius: "10px",
                width: "82%",
                maxWidth: "100%",
              }}
            >
              <h1 style={{ "font-size": "50px" }}>Submitted!</h1>
              <h4>
                Dear User Thank you for your interest in Tata Studi Live.
                <br />
                You will receive a call from our counsellor in your preferred
                time slot.
              </h4>
              <p>
                <Link to="/studilive">
                  {" "}
                  <button className="mt-4 send_btn">
                    Back To Website
                  </button>{" "}
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* <div
        className="d-flex"
        style={{
          backgroundColor: "#00a9ff",
          height: "100vh",
          minHeight: "100%",
          alignItems: "center",
        }}
      >
        <div className="container text-center">
          <div
            className="jumbotron mx-auto"
            style={{
              backgroundColor: "rgba(0,169,255, 0.2)",
              color: "white",
              border: "2px solid white",
              borderRadius: "10px",
              width: "82%",
              maxWidth: "100%",
            }}
          >
            <h1>Page Not Found</h1>
            <p>
              <Link to="/">
                {" "}
                <button className="mt-4 send_btn">
                  Back To HomePage
                </button>{" "}
              </Link>{" "}
            </p>
          </div>
        </div>
      </div> */}
    </>
  )
}
